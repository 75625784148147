/* Header CSS */

.header-area {
    transition: all 500ms ease;
    position: fixed !important;
    width: 100%;
    top: 0 !important;
    left: 0;
    right: 0;
    z-index: 100000;
    background-color: $heading;

    @media #{$breakpoint-lg} {
        box-shadow: none;
        background-color: transparent;
    }

    &.sticky {
        background-color: $heading;
        box-shadow: 0 1rem 3rem rgba(15, 30, 150, 0.175);
    }

    .navbar-toggler {
        padding: .25rem .5rem;
        color: $white !important;
        border-color: rgba(255, 255, 255, 0.175);

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }

        &:focus {
            box-shadow: none;
        }
    }

    .navbar {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @media #{$breakpoint-lg} {
            height: 80px;
            padding-top: 0;
            padding-bottom: 0;
        }

        .navbar-brand {
            margin-right: 2rem;
            line-height: 1 !important;

            @media #{$breakpoint-lg} {
                margin-right: 3rem;
            }

            @media #{$breakpoint-xl} {
                margin-right: 4rem;
            }
        }
    }

    .navbar-nav {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: .5rem;
        padding-bottom: .5rem;

        @media #{$breakpoint-lg} {
            border-top: 0;
            border-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
        }

        .nav-item {
            .nav-link {
                position: relative;
                display: inline-block;
                color: red;
                font-weight: 700;
                font-size: 1rem;
                padding: .375rem .75rem;
                width: 100%;

                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 1.5rem;
                    border: 0 !important;
                    content: "\f229";
                    font-family: bootstrap-icons !important;
                    font-size: 10px;
                    line-height: 1;
                    vertical-align: baseline;
                }

                @media #{$breakpoint-lg} {
                    width: auto;
                    padding: 1.375rem 1rem;

                    &::after {
                        position: relative;
                        top: auto;
                        transform: translateY(0);
                        right: 0;
                        margin-left: .375rem;
                    }
                }

                &:focus {
                    color: $warning;
                }
            }

            &.dropend {
                .nav-link {
                    &::after {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) rotate(-90deg);
                        right: 1.25rem;
                        bottom: 0;
                    }
                }
            }

            .dropdown-menu {
                background-color: rgba(255, 255, 255, 0.1);
                width: 100%;
                border: 0;
                border-radius: .375rem;
                margin: 0;
                -webkit-animation-name: dropdownAnimation;
                animation-name: dropdownAnimation;
                -webkit-animation-duration: 300ms;
                animation-duration: 300ms;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;

                @media #{$breakpoint-lg} {
                    padding: 1rem 0;
                    width: 12.5rem;
                    background-color: $white !important;
                    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.125);
                    margin-left: .25rem;
                }

                a {
                    display: block;
                    transition: all 500ms;
                    position: relative;
                    font-size: 14px;
                    padding: .375rem 1rem;
                    color: $white;
                    font-weight: 700;

                    @media #{$breakpoint-lg} {
                        padding: .375rem 1.5rem;
                        color: $text;
                    }

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: $warning;

                        @media #{$breakpoint-lg} {
                            color: $primary;
                        }
                    }
                }
            }
        }

        div .header-1-textcolor
        {
            color:white;
        }
        div .header-1-textcolor:hover
        {
            color:#ffc107 ;
        }
        div .header-1-textcolor:focus
        {
            color:#ffc107 ;
        }
        div .header-1-textcolor:active
        {
            color:#ffc107 ;
        }
    }

    &.header-2 {
        background-color: $gray;

        @media #{$breakpoint-lg} {
            background-color: transparent;
        }

        .navbar-toggler {
            color: $white !important;
            border-color: $text-gray;

            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }
        }

        .navbar-nav {
            border-top: 1px solid $border;
            border-bottom: 1px solid $border;
            padding-top: .5rem;
            padding-bottom: .5rem;
    
            @media #{$breakpoint-lg} {
                border-top: 0;
                border-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
            div .header-1-textcolor
            {
                color:black;
            }
            div .header-1-textcolor:hover
            {
                color:#ffc107 ;
            }
            div .header-1-textcolor:focus
            {
                color:#ffc107 ;
            }
            div .header-1-textcolor:active
            {
                color:#ffc107 ;
            }
        }

        .nav-item {
            .nav-link {
                color: $heading;

                &:focus {
                    color: $primary;
                }
            }

            .dropdown-menu {
                background-color: $white;

                @media #{$breakpoint-lg} {
                    padding: 1rem 0;
                    width: 12.5rem;
                    background-color: $white !important;
                    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.125);
                }

                a {
                    color: $text;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: "pink";

                        @media #{$breakpoint-lg} {
                            color: $primary;
                        }
                    }
                }
            }
        }

        &.sticky {
            background-color: $gray;
        }
    }
}

@-webkit-keyframes dropdownAnimation {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 24px, 0);
        -ms-transform: translate3d(0, 24px, 0);
        transform: translate3d(0, 24px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes dropdownAnimation {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 24px, 0);
        -ms-transform: translate3d(0, 24px, 0);
        transform: translate3d(0, 24px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}