/* Contact CSS */

.form-control {
    transition-duration: 500ms;
    border-color: $border;
    height: 52px;
    padding: 15px 20px;
    font-size: 14px;

    &:focus {
        box-shadow: none;
    }
}

.contact-form {
    .form-control {
        border-color: $border;
        background-color: $gray;

        &:focus {
            border-color: $primary;
        }
    }

    textarea.form-control {
        height: 150px;
    }
}

.contact-mini-card {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .contact-mini-card-icon {
        position: relative;
        z-index: 1;

        &::after {
            position: absolute;
            z-index: -10;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            top: 0.25rem;
            left: 0.25rem;
            content: "";
            background-color: $text;
        }

        &::before {
            position: absolute;
            z-index: -10;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            bottom: -2px;
            left: 0;
            content: "";
            background-color: $heading;
        }

        i {
            position: relative;
            display: block;
            z-index: 1;
            flex: 0 0 50px;
            max-width: 50px;
            width: 50px;
            height: 50px;
            background-color: $heading;
            text-align: center;
            color: $white;
            border-radius: 50%;
            font-size: 1.5rem;
            line-height: 50px;
        }
    }

    p {
        margin-bottom: 0;
        margin-left: 1rem;
        line-height: 1.25rem;
    }

    &:nth-child(2) {
        .contact-mini-card-icon {

            &::before {
                background-color: $primary;
            }

            i {
                background-color: $primary;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;

        .contact-mini-card-icon {
            &::before {
                background-color: $warning;
            }

            i {
                background-color: $warning;
                color: $heading;
            }
        }
    }
}

.google-maps-wrapper {
    position: relative;
    z-index: 1;

    iframe {
        width: 100%;
        height: 400px;
        border: none;

        @media #{$breakpoint-lg} {
            height: 600px;
        }
    }
}

textarea.form-control {
    height: auto;
}